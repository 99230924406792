.map{
    background-color:  white;
    margin-left: 5%;
    margin-right: 5%;
    padding-bottom: 10px;
    padding-top: 2%;
}

.map-bg-color{
    background-color: green;
}
.map-head{
  color:#027e2a;
  padding-top: 20px;
  font-weight: bold;
  font-size: x-large;
}
.heading_class_new{
    background-color: white;
}

.google-map{
    margin-bottom: 2%;
}

.vanadharsini-log{
    margin-right:50%;
}
.foot-mrg{
    padding-top: 5%;
    padding-bottom: 5%;
    /* background-color: rgb(117, 115, 115); */
    /* background-color: #9d8080; */
    /* background-color: #aa7979; */
    background-color: #866e6e;
}

.list-pages ul {
    list-style: none;
    padding: 0; 
    margin: 0; 
    color: rgb(3, 94, 38);
    text-justify: center;
    text-align: start;
    padding-left: 30%;
    font-size: large;
    font-weight: bold;
  }
  
  .list-pages ul li {
    padding: 5px 0;
  }
 

.email-color {
    margin-top: 2%;
    /* color: white;  */
    font-size: 18px;
    color: #027e2a;
    text-align: center;
  }
  
  .email-color:hover {
    color: rgb(28, 28, 121);  
  }
  
  .termcond-colors {
    margin-top: 2%;
    /* color: white;  */
    font-size: 19px;
    color: #080101;
  
  }
  .termcond-colors u{
    text-decoration: underline;
    text-decoration-color: #02581d;
 
  }
  .termcond-color a:hover {
    color: rgb(28, 28, 121); 
    
  }

  .terms-cond{
    background-color: rgb(116, 114, 114);
    color:white;
    text-align: start;
    justify-content: center;
    margin-left: 0%;
    margin-right: 0%;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 3%;
    padding-bottom: 8%;
    font-size: large;
  }
  .terms-cond h2{
    text-align: center;
  }
  .container-terms{
    background-color: azure;
  }

  .privacy-policy{
    background-color: rgb(116, 114, 114);
    color:white;
    text-align: start;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 3%;
    font-size: large;

  }
  .privacy-policy h2{
    text-align: center;
    padding-top: 2%;
  }
  .privacy-policy ul {
    list-style: none;
  }

  .cancel-pol {
    background-color: rgb(116, 114, 114);
    color:white;
    
    height: 650px;
 
  }

  .cancel-pol h2{
    text-align: center;
    padding-top: 2%;
  }
  .cancel-pols p {
    text-decoration: none; 
        padding-top: 2%;
        font-size: large;
        text-align: center;      
}

.general-rules{
    background-color: rgb(116, 114, 114);
    color:white;
     height: 650px;
     text-align: start;
     font-size: large;
     padding-left: 5%;
     padding-right: 10%;
  
}

.general-rules h2{
    text-align: center;
    padding-top: 2%;
  }

  /* Custom CSS for the feedback form */
body {
    font-family: Arial, sans-serif;
    background-color: #f8f9fa;
    /* background-color: #027e2a; */
    margin-Top: 5%;
    padding-Top: 5%;
  }
  

  





  .form-label {
    /* font-weight: bold; */
    font-size: 16px;
  }
  
  .form-control {
  
    margin-bottom: 20px;
    border: 1px solid #080101;
    border-radius: 4px;
    
  }
  
  .text-danger {
    font-size: 12px;
  }
  
  .btn-primary {
    padding: 10px 30px;
    font-size: 16px;
    justify-content: center;
    text-align: center;
    margin-left: 70px;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
    border: 1px solid #010508;
  }
  
  
  .text-centers{
    color: red;
    padding-bottom: 4%;
  }

.captcha {
    display: inline-block;
    padding: 10px;
    border: 1px solid #ccc; 
    border-radius: 4px; 
    font-size: 20px;
    margin-left: 10px; 
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 25%, #e0e0e0 50%, #f0f0f0 50%, #f0f0f0 75%, #e0e0e0 75%);
    background-size: 200% 100%; 
    animation: paintLines 2s infinite linear; 
  }
  
  @keyframes paintLines {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
  

  .timingsbox_headings h5{
    background-color: green;
    text-align: center;
    font-size: 24px;
    color:white;

  }
  .timingsbox_headings {
    background-color: white;
    text-align: start;
    font-size:16px;
  }

  .form-contact{
    padding-bottom: 10%;
    padding-left: 10%;
    
  }
  
  .heading_class_new{
    text-align: center;
    padding-bottom: 5%;
  }

.bg-color-green{
  background-color: green;
}
  



/* Mamatha styles */


 .termcond-color a {
     text-decoration: none;
     
     /* color: white; */
    color: #027e2a;
  
 }

 .termcond-color a:hover {
     color: #027e2a;
    
     text-decoration: underline;
     
 }




   
     .col-md-3 .list-pages ul li a {
       text-decoration: none;
      
       color: #027e2a;
       
     }

     .col-md-4 .list-pages ul li a {
      text-decoration: none;
    
      color: #027e2a;
      
    }
  
   
     .col-md-3 .list-pages ul li a:hover {
       text-decoration: underline;
       color:#05203d;
       
     }
     .col-md-4 .list-pages ul li a:hover {
      text-decoration: underline;
      color:#05203d;
      
    }

    .footer-bg{
      background-color: #e0dfdf;
    }

    .col-form-label{
      text-align: center;
    }


    @media only screen and (min-width: 350px) and (max-width: 750px) {
      .col-md-10 {
          padding-top: 10%;
         
      }
  }




.termcond-colors button {
  background-color: #3498db;
  color: #ffffff;
  padding: 10px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 5px;
}

.termcond-colors button:hover {
  background-color: #2980b9;
}


@media only screen and (min-width: 320px) and (max-width: 720px) {
  .termcond-colors button {
    width: 100%; 
    box-sizing: border-box; 
  }
}



.footer-content {
  display: flex;
  align-items: center;
  /* Center items vertically */
  justify-content: center;
  /* Center items horizontally */
  height: 100%;
  /* Set height to take up full height of parent */
}

.footer-content>div {
  text-align: center;
  /* Center text horizontally */
}