


  .billing-details{
    text-align: center;
    font-size: larger;
  }
 
  .information-align {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .form-div {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  
  .information-label {
    margin-top: 10px;
    font-weight: bold;
  }
  
  
  .information-fields {
    width: 100%;
    padding: 8px;
    margin: 6px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  

  .button {
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  
  .table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
  }
  
  .table th {
    background-color: #f2f2f2;
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: left;
  }
  
  .table td {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: left;
  }
  