.image-row {
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.image-column {
    margin: 5px;
}




.image-row {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.image-column {
    flex: 1;
    margin-right: 10px;
}

.image-container {
    height: 200px;
   
    width: 100%;
    overflow: hidden;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: fill;
   
    cursor: pointer;
}


/* Add a media query for smaller screens */
@media (max-width: 767px) {
    .image-row {
      flex-direction: column; /* Change to column layout on smaller screens */
    }
  
    .image-column {
      flex: 1;
      margin-right: 0; /* Remove right margin on smaller screens */
      margin-bottom: 10px; /* Add bottom margin for better spacing */
    }
  
    .image-container {
      height: 150px; /* Adjust the height of the image container for better view */
      overflow: hidden;
    }
  }
  
  /* Your existing styles */
  .image-row {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .image-column {
    flex: 1;
    margin-right: 10px;
  }
  
  .image-container {
    height: 200px;
    width: 100%;
    overflow: hidden;
  }
  
  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    cursor: pointer;
  }
  