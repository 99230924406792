
.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.navlink{
    color:white;
    
    opacity:1;
}


.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

:root {
    --white: #fff;
   
    --main-color:#027e2a;
    --grey: #434141;
    --light-grey: #aaa;
}

body {
    background-color: #f1f1f1;
    transition: all 0.5s ease;
  
    background-image:url("https://vanadarshini-images.s3.ap-south-1.amazonaws.com/background-images/waterfall-6.jpg");
    width:auto

}

.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    background-color: var(--white);
    display: flex;
   
    justify-content: space-between;
    padding: 30px 30px;
    z-index: 1000;
    box-shadow: 0 0 2px var(--light-grey);
}
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 200%;
    
    font-size: 16px;
    overflow-y: scroll;
}

.navbar-pad{
    padding-right: 82%;
}

.logo {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: 22px;
    font-weight: 500;
    /* color: var(--main-color); */
    color:#027e2a;
}

.sidebar {
    background-color: var(--white);
    width: 260px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    padding: 80px 10px;
    z-index: 100;
    overflow-y: scroll;
    box-shadow: 0 0 1px var(--light-grey);
    transition: all 0.5s ease;
}

.sidebar.close {
    padding: 60px 0;
    width: 80px;
}

.sidebar::-webkit-scrollbar {
    display: none;
}

.menu_content {
    position: relative;
}
.sidebar {
    position: fixed;
    top: 0;
    left: -25px;
    
    height: 100%;
    overflow-y: auto;
    
}
.menu_title {
    margin: 15px 0;
    padding: 0 20px;
    font-size: 18px;
}

.sidebar.close .menu_title {
    padding: 6px 30px;
}

.menu_title::before {
    color: var(--grey);
    white-space: nowrap;
}

.sidebar.close .menu_title::before {
    content: "";
    position: absolute;
    height: 2px;
    width: 18px;
    border-radius: 12px;
    background: var(--light-grey);
}

.menu_items {
    padding-top: 50px;
    list-style: none;
    height:165vh;
}

.navlink_icon {
    position: relative;
    font-size: 22px;
    min-width: 50px;
    line-height: 40px;
    display: inline-block;
    text-align: center;
    border-radius: 6px;
    color:white;
}

.navlink_icon::before {
    content: "";
    position: absolute;
    height: auto;
    width: calc(100% + 100px);
    left: -20px;
}

.navlink_icon:hover {
 
}

.sidebar .nav_link {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 4px 15px;
    border-radius: 8px;
    text-decoration: none;
    color: var(--grey);
    white-space: nowrap;
}

.sidebar.close .navlink {
    display: none;
    padding: 20px;
}

.nav_link:hover {
    color: var(--white);
    background: var(--main-color);
}

.sidebar.close .nav_link:hover {
    background: var(--white);
}

.collapse_content {
    position: fixed;
    bottom: 100px;
    left: 0;
    width: 260px;
    cursor: pointer;
    transition: all 0.5s ease;
}

.collapse {
    position: absolute;
    display: flex;
    align-items: center;
    left: 0;
    justify-content: space-around;
    padding: 18px 0;
    text-align: center;
    width: 100%;
    color: var(--grey);
    border-top: 1px solid var(--light-grey);
    background-color: var(--white);
}

.collapse i {
    font-size: 20px;
}

.collapse span {
    font-size: 18px;
}

.sidebar.close .collapse_content {
    width: 50px;
    left: 10px;
}

.sidebar.close .collapse span {
    display: none;
    padding: 10px;
}

.sidebar.hoverable .collapse_sidebar {
    display: none;
}

#sidebarOpen {
    display: none;
}

@media screen and (max-width: 768px) {
    #sidebarOpen {
        font-size: 25px;
        display: block;
        margin-right: 10px;
        
        color: var(--grey);
    }
        .sidebar {
          
            overflow-y: scroll;
           
        }

    .sidebar.close {
        left: -100%;
    }

    .sidebar.close .collapse_content {
        left: -100%;
    }
}

.sidebar {
  
    overflow-y: scroll;
}


.btnn
    {
      display: block;
     
      width: 110px;
      height: 50px;
      border: none;
      background-color: #1ca52b;
      color: #fff;
      font-size: 18px;
      border-radius: 6px;
      outline: none;
      cursor: pointer;
      transition: .3s linear;
      text-align: center;
      padding-top: 10px;
      margin-left: 10px;
    }


    .btnnn{
    
  
     
      width: 80px;
      height: 44px;
      border: none;
      background-color: #1ca52b;
      color: #fff;
      font-size: 18px;
      border-radius: 6px;
      outline: none;
      cursor: pointer;
      transition: .3s linear;
      text-align: center;
      margin-top: 10px;
      padding-top:6px;
      margin-left: 2px;
  

    }

    /* .btnnn1{
    
      
width: 70px;
        height: 38px;
        border: none;
        background-color: #1ca52b;
        color: #fff;
        font-size: 15px;
        border-radius: 6px;
        outline: none;
        cursor: pointer;
        transition: .3s linear;
        text-align: center;
        margin-top: 10px;
        padding-top:6px;
        margin-left: 2px;
        font-weight: bold;

 
      } */

    .btn1:hover {
        transform: rotateX(15deg);
        box-shadow: 0 7px 7px #00a8ff;
        color: white;
        
      }




.navbar-text{
    color:black;
    font-size:16px;
}


 



@media (max-width: 767px) { 
    .navbar-right {
      position: relative;
      margin-top: 20px; 
      text-align: center; 
    }
  
    .navbar-right a {
        display: block;
        margin-top: 28px;
        margin-left: -170px;
    }
  }

  @media screen  and (min-width:320px) and (max-width:768px){
  
  .navbar-text {
    color: #cc1a1a;
    font-size: 17px;
    margin-left: -120%;
    padding-top: 43px;
  }
  
}

@media screen  and (min-width:320px) and (max-width:768px){
.logo{
font-size: 15px;
color:white
}
}

@media screen  and (min-width:320px) and (max-width:374px){
    .img-responsive{
 
   width: 54%;
    padding-top: 16px;
    }
    }
@media screen  and (min-width:375px) and (max-width:424px){
    .img-responsive{
 
   width: 50%;
    padding-top: 15px;
    }
    }
    @media screen  and (min-width:425px) and (max-width:440px){
        .img-responsive{
     
       width: 44%;
        padding-top: 16px;
        }
        }
        @media screen  and (min-width:441px) and (max-width:519px){
            .img-responsive{
         
           width: 38%;
            padding-top: 16px;
            }
            }
        @media screen  and (min-width:520px) and (max-width:580px){
            .img-responsive{
           
           width: 34%;
            padding-top: 14px;
            }
            }
            @media screen  and (min-width:581px) and (max-width:620px){
                .img-responsive{
               
               width: 34%;
                padding-top: 9px;
                }
                }
                @media screen  and (min-width:621px) and (max-width:644px){
                    .img-responsive{
                   
                   width: 34%;
                    padding-top: 9px;
                    }
                    }
                    @media screen  and (min-width:645px) and (max-width:767px){
                        .img-responsive{
                       
                       width: 30%;
                        padding-top: 9px;
                        }
                        }
    @media screen  and (min-width:768px) and (max-width:1200px){
        .img-responsive{
     
       width: 80%;
        padding-top: 6px;
        }
        }
        @media screen  and (min-width:1200px) and (max-width:1440px){
            .img-responsive{
         
           width: 75%;
            padding-top: 3px;
            }
            }


    @media screen  and (min-width:320px) and (max-width:400px){

        .carousel-inner{
            padding-top: 20%;
        }
    }


    @media screen  and (min-width:320px) and (max-width:400px){

        .carousel-inner{
            padding-top: 20%;
        }
    }


    @media screen  and (min-width:400px) and (max-width:568px){

        .carousel-inner{
            padding-top: 10%;
        }
    }

    @media screen  and (min-width:568px) and (max-width:768px){

        .carousel-inner{
            padding-top: 7%;
        }
    }

     @media screen  and (min-width:320px) and (max-width:520px){
  
  .navbar-text {
    color: #cc1a1a;
    font-size: 13px;
    margin-left: -363%;
    padding-top: 0px;
  }
  
}
.btnnn1{
    
      
    display:block;   
    width: 80px;
    height: 45px;
    border: none;
    background-color: #1ca52b;
    color: #fff;
    font-size: 18px;
    border-radius: 6px;
    outline: none;
    cursor: pointer;
    transition: .3s linear;
    text-align: center;
    margin-top: 10px;
    padding-top:7px;
    margin-left: 28px;
    font-weight: 500;


  }
  @media screen  and (min-width:320px) and (max-width:767px){
    .btnn{
      width:70%;
       height:10%;
       font-size: 12px;
    }
}

@media screen  and (min-width:768px) and (max-width:1200px){
    .btnn{
        width:60%;
      
       height:10%
    }
}

@media screen  and (min-width:767px) and (max-width:1200px){
    .btnnn{
        width:90px;
        height:48px;
        padding-left:4px;
    }
}


@media screen and (min-width:320px) and (max-width:1200px){
    .content_back {
        background-color: #FFFFFF;
        padding: 60px !important;
        border: 3px solid #069306;
        border-radius: 15px;
    }
}

/* @media screen  and (min-width:320px) and (max-width:424px){
  
    .navbar-text {
      color: #cc1a1a;
      font-size: 13px;
      margin-left: -266%;
      padding-top: 0px;
    }
    
  } */
  @media screen  and (min-width:425px) and (max-width:520px){
    
      .navbar-text {
        color: #cc1a1a;
        font-size: 13px;
        margin-left: -236%;
        padding-top: 0px;
      }
      
    }

    @media screen  and (min-width:520px) and (max-width:768px){
    
        .navbar-text {
          color: #cc1a1a;
          font-size: 18px;
          margin-left: -56%;
          padding-top: 0px;
        }
        
      }