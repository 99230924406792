
.reports-bi{
  width:850px;
  height:600px;
  justify-content: center;
  text-align: center;
}



  @media screen  and (min-width:320px) and (max-width:374px){
    .reports-bi{
  
  width:295px;
  height:200px;
   
    }
    }

  @media screen  and (min-width:375px) and (max-width:424px){
    .reports-bi{
  
  width:350px;
  height:250px;
  text-align: center;
  justify-content: center;
   
    }
    }
    
  @media screen  and (min-width:425px) and (max-width:566px){
    .reports-bi{
  
  width:400px;
  height:300px;
   
    }
    }

    @media screen  and (min-width:567px) and (max-width:666px){
      .reports-bi{
    
    width:500px;
    height:350px;
     
      }
      }

      @media screen  and (min-width:667px) and (max-width:768px){
        .reports-bi{
      
      width:500px;
      height:380px;
       
        }
        }

        @media screen  and (min-width:768px) and (max-width:1024px){
          .reports-bi{
        
        width:680px;
        height:500px;
         
          }
          }
    
            @media screen  and (min-width:1440px) and (max-width:2560px){
              .reports-bi{
            
            width:1000px;
            height:600px;
             
              }
              }

     .image-containers {
      position: relative;
      overflow: hidden;
      padding-bottom: 100%;
      
  }
  
  .img-responsives {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      
  }
  
  
  
  
  .search_button {
    
    background-color: #27B0E6;
  border: none;
  border-radius: 6px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  width: 206px;
  }
  .btnclass{
      box-sizing: border-box;
      width: 12em;
      height: 3em;
      font-size: 20px;
      border-radius: 0.5em;
      margin: 0.5em;
      box-shadow: 0 0 1em rgba(0,0,0,0.2);
      color: white;
      font-family: sans-serif;
      text-transform: capitalize;
      line-height: 3em;
      transition: 0.3s;
      cursor: pointer;
  }
  .btnclass:nth-child(odd) {
      background: linear-gradient(to right, blue, rgb(255, 71, 105));
      text-align: left;
      padding-left: 10%;
      transform: perspective(500px) rotateY(45deg);
  }
  .btnclass:nth-child(odd):hover {
      transform: perspective(200px) rotateY(45deg);
      padding-left: 5%;
  }
  
  
  
  
  .form-control{
  background-color: white !important;
  }
  
  
  .booking_button {
    background-color: #04AA6D; 
    border: none;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 175px;
    border-radius: 10px;
  }
  .blocking_button {
    background-color: #aa9904; 
    border: none;
    color: white;
    padding: 15px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 175px;
    height: 60px;
    border-radius: 5px;
  }

  .blocking_button:hover {
    transform: rotateX(-15deg);
    box-shadow: 0 -15px 15px #8a941d;
    background-color: #809c0d;
    color: black;
  }

  .btn
  {
    display: block;
    margin: 40px 0;
    width: 150px;
    height: 60px;
    border: none;
    background-color: #00a8ff;
    color: #fff;
    font-size: 18px;
    border-radius: 6px;
    outline: none;
    cursor: pointer;
    transition: .3s linear;
  }
  .btn1:hover {
      transform: rotateX(15deg);
      box-shadow: 0 7px 7px #00a8ff;
      background-color: #00a8ff;
    }
    
    .btn2:hover {
      transform: rotateX(-15deg);
      box-shadow: 0 -5px 7px #00a8ff;
      background-color: #00a8ff;
    }
    
    .btn3:hover {
      transform: rotateY(15deg);
      box-shadow: -5px 0 7px #00a8ff;
      background-color: #00a8ff;
    }
    
    .btn4:hover {
      transform: rotateY(-15deg);
      box-shadow: 5px 0 5px #00a8ff;
      background-color: #00a8ff;
    }
  
  
  
    .sr_card{
      padding: 16px;
      border-radius: 10px;
      border: 1px solid rgb(180, 178, 178);
      margin: 10px 0px ;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      box-shadow: 0px 0px 2px 2px lightgreen;
    }
    
    .sr_card_red{
      padding: 16px;
      border-radius: 10px;
      border: 1px solid rgb(180, 178, 178);
      margin: 10px 0px ;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      box-shadow: 0px 0px 2px 2px rgb(182, 181, 181);
      background-color: #e0dddd;
      opacity: .5;
      
    }
    
    .sr_card:hover {
        background-color:#cce4f5
      }
      .sr_card_photo img {
        border-radius:3px;
        vertical-align:top
      }
      .sr_card_content {
        padding-left:16px;
        -webkit-box-ordinal-group:3;
        -webkit-order:2;
        -ms-flex-order:2;
        order:2;
        -webkit-flex-basis:200px;
        -ms-flex-preferred-size:200px;
        flex-basis:200px;
        -webkit-box-flex:1;
        -webkit-flex-grow:1;
        -ms-flex-positive:1;
        flex-grow:1;
        display:-webkit-box;
        display:-webkit-flex;
        display:-ms-flexbox;
       
        -webkit-box-orient:vertical;
        -webkit-box-direction:normal;
        -webkit-flex-direction:column;
        -ms-flex-direction:column;
        flex-direction:column
      }
      .date-input {
        display: flex;
        justify-content: space-between;
        width: 130px;
      }
    
      .container-row{
        display: flex;
      /* justify-content: space-between; */
      justify-content: space-evenly;
        flex-wrap: wrap;
        align-content: stretch;
        padding-top:20px
      }
    
      .react-datepicker-wrapper input{
        width: 80% !important;
      }
      .message_text{
        text-align: center;
        color: red;
      }
    
      .booking_button {
        background-color: #04AA6D; 
        border: none;
        color: white;
        padding: 15px 30px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        width: 185px;
      }
    
      .back_button {
        background-color: #aa4604; 
        border: none;
        color: white;
        padding: 15px 15px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        width: 185px;
        border-radius: 10px;
      }
    
  
      .float_right{
        float: right;
      }

      
.float_left {
  float: left;
}

@media (max-width: 767px) {
  .button-container {
    display: flex;
    flex-direction: column;
  }

  .float_left, .float_right {
    float: none;
  }

  .back_button {
    order: 2;
    margin-top: 10px; 
  }

  .booking_button {
    order: 1;
  }
}
    
      .center_title{
        text-align: center;
        font-size: 18px;
        color: green;
      }
    
      .billing_details_form-control{
       
        padding-left: 10px !important;
      }
    
      @media only screen and (min-width: 768px) and (max-width: 1023px) {
        .billing_details_form-control {
          width: 215%; 
         
        }
      }
      .container-billing{
        text-align: left;
        overflow: hidden;
        width: 100%;
        margin: 0 auto;
          margin-bottom: 0px;
        display: table;
        padding: 0 0 2em 0;
        margin-bottom: 20px;
      }
    
      .form-control-captcha {
        width: 200px !important;
        padding-left: 10px !important;
      }
    
      .billing_details_form-control  textarea {
        min-height: 100px !important;
      }
    
      .search_button {
    
        background-color: #27B0E6;
      border: none;
      color: white;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      width: 206px;
      }
    
      .react-datepicker, .react-datepicker__header {
        width: 740px !important;
        margin-left: -12px;
      }
    
      .react-datepicker__header {
        width: 740px !important;
        margin-left: 1px;
      }
    
      .react-datepicker__day, .react-datepicker__day-name{
        width: 100px !important;
        font-size: 15px !important;
        color: black !important;
        height:50px;
      } 
    
      .react-datepicker__day--disabled{
        opacity: 0.2 !important;
      } 
    
      .callend_available_red{
        color: red;
        font-size: 11px !important;
        height: 12px !important;
      }
    
      .callend_available_green{
        color: green;
        font-size: 9px !important;
        height: 12px !important;
      }
    
      .react-datepicker__day--selected {
        background-color: #84D3F2 !important;
      }
    
      .react-datepicker__current-month {
        font-size: 14px !important;
      }
    .react-datepicker__day--outside-month {
      color: rgb(240, 24, 115) !important;
      
    }
    
    .react-datepicker__day--weekend {
      color: black !important;
      background-color: rgb(214, 214, 115) !important;
    }
  
    .rmdp-day {
      width: 70px !important;
      font-size: 15px !important;
      color: black !important;
      height:75px !important;
    }
    .rmdp-week-day{
      width: 60px !important;
      font-size: 15px !important;
      color: black !important;
      height:45px !important;
    }
    .rmdp-disabled{
      opacity: .5;
      color:grey
    }

    .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
        background-color: #7ea6f0;
        color: #dc1d1d;
    }

  .rmdp-day.rmdp-today span:hover {
    background-color: #7fdbff; 
    color:brown;
}
    .rmdp-week, .rmdp-ym {
      display: flex;
      justify-content: space-between;
      margin: -6px;
  }
    
    .rmdp-input {
   
      height: 30px !important;
    }
    
    .rmdp-day span{
      border-radius: 0% !important;
      height: 50px !important;
    }
    
 
  
    .rmdp-header {
      width: 100% !important;
      margin-left: 1px;
      background-color: rgb(196, 192, 192) !important;
    }
    
    .rmdp-calendar  {}
  
    .rmdp-wrapper{
      position: absolute !important;
    left: -430px !important;
    }
  
    .ep-arrow {
      left:30px !important;
    }
    .rmdp-range.start{
      border-bottom-left-radius: 0% !important;
      border-top-left-radius:0% !important;
    }
  
    .rmdp-range.end{
      border-bottom-right-radius: 0% !important;
    border-top-right-radius:0% !important;
    }
  
    .rmdp-day.rmdp-today span{
      background-color: #ebeded00 !important;
    }


    .rmdp-day-picker {
      padding: 30px;
  }

  /* Example mobile-responsive styles for the datepicker */
@media only screen and (min-width:350px) and (max-width: 767px) {
  .rmdp {
    flex-direction: column;
  }
}


@media (max-width: 767px) {
  .container-row {
    flex-direction: column;
    
  }

  .rmdp-calendar {
    margin-top: 20px; 
    margin-left: 363px;
  }
}

.custom-day-picker .DayPicker-Day--disabled {
  color: #ccc; 
  background-color: #f2f2f2; 
}

.rmdp-day.rmdp-today span{
  color:black !important;
}

.rmdp-disabled{
  opacity: .4;
}

.cancelled-room {
  background-color: #FFEBEE; 
  color: #B71C1C; 
}
.form-control.billing_details_form-control#proof_type {
  height: 35px; 
}

.cancel-pol-notes{
  font-size: 21px;
  margin-right:120px;
}
.cancel-pol-note-h1{
 margin-top: 50px;
}


/* .location-buttons-container {
  display: flex;
  justify-content:space-between;
  margin-right: 2px;
}

.location-button {
  margin: 0 5px;
 
  padding: 20px 20px;

  border: none;
  border-radius: 5px;
  cursor: pointer;
}


.location-button.talakona {
  background-color: #007bff;
  
  color: #fff;

}


.location-button.mamandur {
  background-color: #007bff;

  color: #fff;
}


.location-button:active,
.location-button:focus {
  background-color: green;

  color: #000;
 
} */


.location-buttons-container {
  display: flex;
  justify-content: space-around;
  /* Adjust as needed */
}

.location-button {
  width: 200px;
  height: 70px;
  /* background: linear-gradient(to left top, #c32c71 50%, #b33771 50%); */
  background: linear-gradient(to left top, #007bff 50%, #1460ac 50%);
  border-style: none;
  color: #fff;
  font-size: 23px;
  letter-spacing: 3px;
  font-family: 'Lato';
  font-weight: 600;
  outline: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all .5s;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, .2);
}


.location-button:hover {
  transition: all .5s;
  transform: rotate(-3deg) scale(1.1);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, .4);
  /* background: linear-gradient(to left top, #c32c71 50%, #b33771 50%);/ */
  background: linear-gradient(to left top, #2cc32c 50%, #9fac14 50%);
}

.location-button:active,
.location-button:focus {
  transition: all .5s;
  transform: rotate(-3deg) scale(1.1);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, .4);
  background: linear-gradient(to left top, #43c32c 50%, #14ac6a 50%);

}


/* Wrapper styles remain the same */

/* Button styles */





.sum-total {
  margin-top: 20px;
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
}

.sum-total b {
  font-size: 18px;
  color: #333;
}

.sum-total .total-amount {
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
  /* Example color */
}