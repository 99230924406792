.text-center{
    text-align: center;
    color: red;
}

.booking_status_blocked{
background-color: transparent !important;
color: brown !important;
}

.booking_status_booked{
    background-color: transparent !important;
    color: green !important;


    }

.table-data-center{
    text-align: center;
}
  
    .data-row-head{
        font-size: 14px;
    }
    .data-row-data{
        font-size: 14px;
    }
    .text-center{
        text-align: center;
        color: red;
    }
    
    .booking_status_blocked{
    background-color: transparent !important;
    color: brown !important;
    text-align: center;
    }
    
    .booking_status_booked{
        background-color: transparent !important;
        color: green !important;
        text-align: center;
    
    
        }
    
    
    
  
    .cancelled-room {
        background-color: #FFEBEE; 
        color: #B71C1C; 
    }
    

 


button.disabledButton {
    background-color: #d3d3d3; 
    cursor: not-allowed;
}




.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.btn-next-my{
    padding: 10px 15px;
    margin: 0 4px;
    cursor: pointer;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
}

.btn-next-my:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed;
}

