.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}




@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed);

body {

    
    background-attachment: fixed;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
    color: #000000 !important;
    font-size: 12px !important;

}


@media (max-width: 767px) {

    #Logo1,
    #Logo2 {
        width: 50%;
    }

    #Logo3 {
        width: 40%;
    }

    #Logo1,
    #Logo2,
    #Logo3 {
        float: left;
        vertical-align: bottom;
        height: 120px;
    }

    #Logo3 img {
        vertical-align: bottom;
        padding-top: 40px;
    }

    #rightSideMenu {
        padding-top: 26px;
    }
}

@media (min-width: 768px) {

    #Logo1,
    #Logo2 {
        width: 125px;
    }

    #Logo3 {
        width: 40%;
    }

    #Logo1,
    #Logo2,
    #Logo3 {
        float: left;
        vertical-align: bottom;
        height: 124px;
    }

    #Logo3 img {
        vertical-align: bottom;
        padding-top: 37px;
    }

    #rightSideMenu {
        padding-top: 43px;
    }

}

@media (min-width: 992px) {
    #rightSideMenu {
        padding-top: 50px;
    }
}



@media (min-width: 1200px) {
    #rightSideMenu {
        padding-top: 16px;
    }

    #Logo1,
    #Logo2,
    #Logo3 {
        float: left;
        vertical-align: bottom;
        height: 135px;
    }

    #Logo3 img {
        vertical-align: bottom;
        padding-top: 30px;
    }
}

@media (min-width: 768px) {
    .sidebar-nav .navbar .navbar-collapse {
        padding: 0;
        max-height: none;
    }

    .sidebar-nav .navbar ul {
        float: none;
        display: block;
       
    }

    .sidebar-nav .navbar li {
        float: none;
        display: block;
        font-size: 16px;
    }

    .sidebar-nav .navbar li a {
        padding-top: 12px;
        padding-bottom: 12px;
        line-height: 0.75em;
    }
}

.carousel-inner>.item>img,
.carousel-inner>.item>a>img {
    width: 100%;
    margin: auto;
    height:400px;
    
}

.carousel-inner>.item>img {
    border-radius: 10px;
}




@media (min-width: 992px) {
    .sidebar-nav .navbar li a {
        line-height: 1.2em;
    }
}

@media (min-width: 1200px) {
    .sidebar-nav .navbar li a {
        line-height: 1.1em;
    }
}


.navbar-collapse.collapse.sidebar-navbar-collapse {
    background-color: #559A0C;
    color: #FFFFFF;
    border-radius: 10px;
}

#rightSideMenu .navbar-default .navbar-nav>li>a,
#rightSideMenu .navbar-default .navbar-nav>li>a:focus,
#rightSideMenu .navbar-default .navbar-nav>li>a:hover {
    color: #FFF;
}

#rightSideMenu .navbar-default .navbar-nav>li {
    border-bottom: 1px solid #B4D294;
}

#rightSideMenu .navbar-default .navbar-nav>li:last-child {
    border-bottom: none;
}

#rightSideMenu .navbar-default {
    background: none;
    border: none;
}

#LeftCotnent p {
    text-align: justify;
    text-indent: 30px;
}

#rightSideMenu .navbar-default .navbar-collapse,
#rightSideMenu .navbar-default .navbar-form {
    border: none;
}

.height360 {
    height: 350px;
}

.height360 p {
    line-height: 1.7em;
    text-align: justify;
    margin: 0px;
}

.attractions {
    background-color: green;
    padding: 10px 15px 10px 15px;
    
    font-size: 24px;
   
    border-radius: 10px;
    text-align: center;
    color: white;

}

#footer {
    background-image: "url(https://vanadarshini-images.s3.ap-south-1.amazonaws.com/logos/footer_bg.jpg)";
    z-index: 100;
    padding-bottom: 20px;
}

#footer .img-thumbnail {
    padding: 2px;
}

#footer1 {
    background-color: #000;
    padding-top: 30px;
    padding-bottom: 80px;
}

#footer1 .row1 {
    margin-right: 0px;
}

.BottomLinks {
    list-style: none;
}

.BottomLinks li {
    line-height: 1.8em;
}

.BottomLinks a {
    color: #FFF;
    text-decoration: none;
    
}

.navbar-nav li a {
    text-decoration: none;
}

.BottomLinks a:hover,
.BottomLinks a:focus {
    color: #4DA54D;
    text-decoration: none;
  
}


.leftPadding25 {
    padding-left: 25px;
}

.row1 {
    margin-left: -15px;
}

.row1 h1 {
    font-family: 'Roboto', sans-serif;
   
}

.row1 p {
    text-align: justify;
    line-height: 1.8em;
    font-family: 'Roboto';
        font-size: 16px;
}


img.img-thumbnail {
    padding: 1px;
}

#Attractions h4 {
  
}



.btnt-green {
    color: #fff;
    background-color: #559A0C;
    border-color: #4cae4c;
    text-decoration: none;
  
}

.btnt-green:focus,
.btnt-green.focus {
    color: #fff !important;
    background-color: #449d44;
    border-color: #255625;
}

.btnt-green:hover,
.btnt-green.hover {
    color: #339966 !important;
    background-color: white;
    border-color: #398439;
}

.btnt-green:active,
.btnt-green.active,
.open>.dropdown-toggle.btnt-green {
    color: #fff;
    background-color: #449d44;
    border-color: #398439;
}

.btnt-green:active:hover,
.btnt-green.active:hover,
.open>.dropdown-toggle.btnt-green:hover,
.btnt-green:active:focus,
.btnt-green.active:focus,
.open>.dropdown-toggle.btnt-green:focus,
.btnt-green:active.focus,
.btnt-green.active.focus,
.open>.dropdown-toggle.btnt-green.focus {
    color: green;
    background-color: #398439;
    border-color: #255625;
}

.btnt-green:active,
.btnt-green.active,
.open>.dropdown-toggle.btnt-green {
    background-image: none;
}

.AttractionItems {
    
    margin-bottom: 40px;
}

.AttractionItems h4 {
    font-size: 20px;
    
    /* color: #FF0000; */
    color:#f31737e0;
}

#RightSideIamges img {
    margin-bottom: 20px;
}

.room_details {
    background-color: #FFFFFF;
}

.orange {

    font-size: 150%;

}

.tble-color {
    color: black;
}

.timingsbox_heading {
    font-size: 13px;
    background-color: #069306;
    padding: 4px;
    color: #FFFFFF;
}

.content_back {
    background-color: #FFFFFF;
    
    padding: 50px !important;
    
    border: 3px solid #069306;
        border-radius: 15px;

}

.links_class {
    font-size: 18px;
}

.accomi_data {
    border: 1px solid #8c990d;
}
p{
    font-size: 16px;
}

.sp_font {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    color: #a50203 !important;
    
    font-weight: bold;
    padding-left: 0px;
    line-height: 20px !important;
    ;
}


.heading {
    text-align: left;
    height: 40px;
    padding-top: 0px;
    font-size: 22px;

    padding-left: 20px;
    font-family: sans-serif;
    font-weight: bold;
}

.details_block {
    background-color: #FFFFFF;
    
    padding: 50px !important;
    border: 2px solid #069306;
}

.details_block tr,
td {
    padding: 10px !important;
}

.m_active {
    color: #000000 !important;
    font-weight: bold;
    
}

.slider_content_back {
    border: 2px solid #069306;
    border-radius: 10px;
}

.online_booking {
    width: 95%;
    padding-left: 20px;
}



.body {
    margin: 0;
    background: #fff;
    padding: 5px;
    font: 11px Verdana, Arial;
    background: #FFFFFF "url(images/svs_gif_final.gif)" no-repeat center top;
}

#slideshow {
    list-style: none;
    color: #fff
}

#slideshow span {
    display: none
}

#wrapper {
    width: 306px;
    margin: 50px auto;
    display: none
}

#wrapper * {
    margin: 0;
    padding: 0
}

#fullsize {
    position: relative;
    width: 300px;
    height: 225px;
    padding: 2px;
    border: 1px solid #ccc;
    background: #000
}

#information {
    position: absolute;
    bottom: 0;
    width: 300px;
    height: 0;
    background: #000;
    color: #fff;
    overflow: hidden;
    z-index: 200;
    opacity: .7;
    filter: alpha(opacity=70)
}

#information h3 {
    padding: 4px 8px 3px;
    font-size: 14px
}

#information p {
    padding: 19px 8px 8px
}

#image {
    width: 300px
}

#image img {
    position: absolute;
    z-index: 25;
    width: auto
}

.imgnav {
    position: absolute;
    width: 25%;
    height: 206px;
    cursor: pointer;
    z-index: 150
}

#imgprev {
    left: 0;
    background: "url(images/left.gif)" left center no-repeat
}

#imgnext {
    right: 0;
    background: "url(images/right.gif)" right center no-repeat
}

#imglink {
    position: absolute;
    height: 195px;
    width: 100%;
    z-index: 100;
    opacity: .4;
    filter: alpha(opacity=40)
}

.linkhover {
    background: "url(images/link.gif)" center center no-repeat
}

#thumbnails {
    margin-top: 15px
}

#slideleft {
    float: left;
    width: 20px;
    height: 85px;
    background: "url(images/scroll-left.gif)" center center no-repeat;
    background-color: #222
}

#slideleft:hover {
    background-color: #333
}

#slideright {
    float: right;
    width: 20px;
    height: 85px;
    background: #222 "url(images/scroll-right.gif)" center center no-repeat
}

#slideright:hover {
    background-color: #333
}

#slidearea {
    float: left;
    position: relative;
    width: 256px;
    margin-left: 5px;
    height: 145px;
    overflow: hidden
}

#slider {
    position: absolute;
    left: 0;
    height: 145px
}

#slider img {
    cursor: pointer;
    border: 1px solid #666;
    padding: 2px
}







.btnt3d {
    position: relative;
    top: -6px;
    border: 0;
    transition: all 40ms linear;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 2%;
}

.btnt3d:active:focus,
.btn3d:focus:hover,
.btnt3d:focus {
    -moz-outline-style: none;
    outline: medium none;
}

.btn3d:active,
.btn3d.active {
    top: 2px;
}

.btn3d.btn-white {
    color: #666666;
    box-shadow: 0 0 0 1px #ebebeb inset, 0 0 0 2px rgba(255, 255, 255, 0.10) inset, 0 8px 0 0 #f5f5f5, 0 8px 8px 1px rgba(0, 0, 0, .2);
    background-color: #fff;
}

.btn3d.btn-white:active,
.btn3d.btn-white.active {
    color: #666666;
    box-shadow: 0 0 0 1px #ebebeb inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, .1);
    background-color: #fff;
}

.btn3d.btn-default {
    color: #666666;
    box-shadow: 0 0 0 1px #ebebeb inset, 0 0 0 2px rgba(255, 255, 255, 0.10) inset, 0 8px 0 0 #BEBEBE, 0 8px 8px 1px rgba(0, 0, 0, .2);
    background-color: #f9f9f9;
}

.btn3d.btn-default:active,
.btn3d.btn-default.active {
    color: #666666;
    box-shadow: 0 0 0 1px #ebebeb inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, .1);
    background-color: #f9f9f9;
}


.btn3d.btn-success {
    box-shadow: 0 0 0 1px #31c300 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #5eb924, 0 8px 8px 1px rgba(0, 0, 0, 0.5);
    background-color: #78d739;
}

.btn3d.btn-success:active,
.btn3d.btn-success.active {
    box-shadow: 0 0 0 1px #30cd00 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, 0.3);
    background-color: #78d739;
}



.content {
    transition: margin-left 0.3s;
    margin-left: 0;
    
}

.content-expanded {
    margin-left: 250px;
    
}



.content_back {
    font-size: 16px;

}


@media screen and (max-width: 768px) {
    .content_back {
        font-size: 14px;
        
    }
}


@media screen and (max-width: 576px) {
    .content_back {
        font-size: 12px;
        
    }
}







.square-card {
    height: 200px;
}

.even {
    color: white !important;
}

.odd {
    color: white !important;
}

.green {
    background-color: #339966 !important;
}

.orange {
    background-color: #fabb51 !important;
}

.card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.card-title {

    font-size: 24px;
    text-align:center;
    color:white;
    margin-top: 10%;
    text-transform: capitalize;
}

.btnt {
   
    margin-bottom: 5%;
}


.btnt-orange {
    color: white;
    background-color: #fabb51;
    padding:15px 30px 15px 30px;
    font-size: 16px;
    text-decoration: none;
}

.btnt-green {
    color: white;
    background-color: #339966;
    padding: 15px 30px 15px 30px;
    font-size: 16px;
}


.btnt-orange {
    color: #fff;
    background-color: #fabb51;
    border-color: #4cae4c;

}

.btnt-orange:focus,
.btnt-orange.focus {
    color: #339966 !important;
    background-color: #fabb51;
    border-color: #255625;
}

.btnt-orange:hover,
.btnt-orange.hover {
    color: #339966 !important;
    background-color:white;
    border-color: #398439;
}
@media (min-width: 768px) 
{.col-md-10 {
    flex: 0 0 auto;
    width: 82.333333%;
}
}
@media (min-width: 576px)
{.col-sm-10 {
    flex: 0 0 auto;
    width: 83.333333%;
    /* width:100% */
  
}
}
@media screen and (min-width: 768px) and (max-width:1200px)
{.col-sm-10 {
    flex: 0 0 auto;
    width: 83.333333%;
    /* width:100% */
  
}
}



@media only screen and (max-width: 768px) and (min-width: 400px)
    {.col-sm-10 {
        flex: 0 0 auto;
        width: 100%;
       
    }
    
}


@media screen and (min-width:320px) and (max-width:1200px){
    .content_back {
        background-color: #FFFFFF;
        padding: 15px !important;
        border: 3px solid #069306;
        border-radius: 15px;
    }
}