@media print {
    body {
      display:block;
      justify-content: center;
      align-items: center;
      height: 50vh;
      margin-left: 14%;
      
  
    }
    @media print {
        .logo1, .logo2 {
          display: block !important;
        }
      }
      
    @media print {
        .print-logo {
            display: block; 
            margin: auto; 
        }
    
     
    }
   
.logos-container {
    display: flex;
    justify-content: space-between; 
  }
  

    #print_content {
      width: 100%;
      max-width: none; 
      margin: auto;
    }

    .float_left,
    .float_right {
      display: none; 
    }
  }
