.login-form{
    text-align: center;
    justify-content: center;
    /* padding-right: 80px;  */
}

.login-button{
    justify-content: center;
    text-align: center;
    margin-left: 40%;
    height:60px;
   
    
}
.btnss
{
  display: block;
  margin: 20px 0;
  width: 150px;
  height: 50px;
  border: none;
background-color: #0d6efd;
  color: #fff;
  font-size: 18px;
  border-radius: 6px;
  outline: none;
  cursor: pointer;
  transition: .3s linear;
}
@media screen  and (min-width:320px) and (max-width:374px){
    .login-button{
   
      margin-left: auto;
        margin-right: auto;
        display: block;
    }
}
@media screen  and (min-width:375px) and (max-width:424px){
    .login-button{
   
      margin-left: auto;
        margin-right: auto;
        display: block;
    }
}
@media screen  and (min-width:425px) and (max-width:767px){
    .login-button{
   
      margin-left: auto;
        margin-right: auto;
        display: block;
    }
}
@media screen  and (min-width:468px) and (max-width:1023px){
    .login-button{
   
      margin-left: auto;
        margin-right: auto;
        display: block;
    }
}

@media screen  and (min-width:1024px) and (max-width:2000px){
    .login-button{
   
      margin-left: auto;
        margin-right: auto;
        display: block;
    }
}

/* password code */

.for-password{
    text-align: center;
    justify-content: center;
}

.btns1
    {
      
     width:110px;
      height: 50px;
      border: none;
      background-color: #1ca52b;
      color: #fff;
      font-size: 16px;
      border-radius: 6px;
      outline: none;
      cursor: pointer;
      transition: .3s linear;
      text-align: center;
     
    
    }