.modal-content{
    width: 524px !important;
}

.form-control{
height: calc(3.25rem + 2px);
}

.btn{
    margin:10px 0px;
}

.booking_buttons {
    background-color: #04AA6D; /* Green */
    border: none;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 200px;
    border-radius: 10px;
  }
  .booking_buttons {
    background-color: #04AA6D; /* Green */
    border: none;
    color: white;
    padding: 15px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 200px;
  }

@media (min-width: 320px) and (max-width: 374px) {
    .booking_buttons {
        width: 120px; 
        height: 50px; 
        padding: 5px 10px;
        text-align: center;
        font-weight: bold;
      
    }
}

@media (min-width: 375px) and (max-width: 424px) {
  .booking_buttons {
      width: 140px; 
      height: 56px; 
      padding: 5px 10px;
      text-align: center;
      font-weight: bold;
    
  }
}
@media (min-width: 425px) and (max-width: 767px) {
.booking_buttons {
    width: 180px; 
    height: 56px; 
    padding: 5px 10px;
    text-align: center;
    font-weight: bold;
  
}
}

@media (min-width: 768px) and (max-width: 1023px) {
.booking_buttons {
  width: 200px; 
  height: 56px; 
  padding: 5px 10px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;

}
}
